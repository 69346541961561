<template>
  <div class="card-flip" :class="{ isFlipped: flipped }">
    <div class="flip-toggle">
      <div class="flip-toggle-back" v-if="flipped" @click="flipped = false">
        <img
          :src="`/themes/${$route.params.slug}/flip_btn_front.png`"
          v-if="flipped"
          style="width: 100%; height: auto"
        />
      </div>
      <div class="flip-toggle-front" v-else @click="flipped = true">
        <img
          :src="`/themes/${$route.params.slug}/flip_btn_back.png`"
          v-if="!flipped"
          style="width: 100%; height: auto"
        />
      </div>
    </div>
    <div class="card-flip-inner">
      <div class="card-flip-front">
        <CardBase :imageURL="imageURL" :orientation="orientation"></CardBase>
      </div>
      <div class="card-flip-back">
        <CardBase
          :imageURL="`/themes/${$route.params.slug}/flip_background_${orientation}.png`"
          :orientation="orientation"
        ></CardBase>
      </div>
    </div>
  </div>
</template>

<script>
import CardBase from '@/components/CardBase.vue';

export default {
  components: { CardBase },
  props: {
    flipState: Boolean,
    imageURL: String,
    backURL: String,
    position: String,
    orientation: { type: String, default: 'portrait' },
  },
  data() {
    return {
      flipped: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-flip {
  background-color: transparent;
  width: 100%;
  margin: 0 auto;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-toggle {
  position: relative;
  margin-bottom: 20px;
  height: 44px;

  .flip-toggle-front,
  .flip-toggle-back {
    position: absolute;
    top: 0;
    left: 50%;
    width: 128px;
    height: auto;
    transform: translateX(-50%);
  }
}
.card-flip-inner {
  position: relative;
  width: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.isFlipped .card-flip-inner {
  transform: rotateY(180deg);
}

.card-flip-front,
.card-flip-back {
  width: 100%;
  backface-visibility: hidden;
}

.card-flip-back {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
}
</style>
