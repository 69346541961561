var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"share-social"},[(_vm.isKiosk)?_c('VueQRcode',{staticClass:"qr-code",attrs:{"scale":3,"maskPattern":4,"width":12,"value":_vm.shareURL}}):_vm._e(),_c('div',{staticClass:"share-icons"},[(
        _vm.$route.params.slug !== 'msutunnel' &&
        _vm.$route.params.slug !== '2021buickkaraoke' &&
        _vm.$route.params.slug !== '2021buickkaraokevolleyball'
      )?_c('button',{staticClass:"social-icon icon-email",on:{"click":function($event){return _vm.$emit('shareEmail', 'shareEmail')}}}):_c('button',{staticClass:"social-icon icon-sms",on:{"click":function($event){return _vm.$emit('shareSMS', 'shareSMS')}}}),(
        _vm.$route.params.slug !== 'msutunnel' &&
        _vm.$route.params.slug !== '2021buickkaraoke' &&
        _vm.$route.params.slug !== '2021buickkaraokevolleyball'
      )?_c('button',{staticClass:"social-icon icon-sms",on:{"click":function($event){return _vm.$emit('shareSMS', 'shareSMS')}}}):_c('button',{staticClass:"social-icon icon-email",on:{"click":function($event){return _vm.$emit('shareEmail', 'shareEmail')}}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }