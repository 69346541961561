<template>
  <transition appear name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" style="height: 464px" :class="[`type-${type}`, sent ? 'sent' : '']">
          <div class="modal-header">
            <div class="icon"></div>
            <h2></h2>
            <CloseButton v-if="!sent" class="x-cancel" @click="$emit('close')" />
          </div>

          <div v-if="!sent" class="modal-body">
            <TikTokUpload v-if="type === 'tiktok'" />

            <div v-else-if="type === 'email' || type === 'instagram'" style="height: 76px" class="field email">
              <label for="email">Email</label>
              <textarea
                type="email"
                id="email"
                name="recipient"
                placeholder="recipient's email address"
                v-model="emailAddress"
                style="text-transform: uppercase; height: 50px"
              />
            </div>
            <div v-else-if="type === 'sms'" style="height: 76px" class="field sms">
              <label for="sms">Phone</label>
              <textarea
                type="tel"
                id="sms"
                name="recipient"
                v-model="phoneNumber"
                placeholder="recipient's phone number"
                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                style="text-transform: uppercase; height: 50px"
              />
            </div>
            <div class="field message" v-if="type !== 'tiktok'">
              <textarea
                id="message"
                v-model="message"
                rows="10"
                cols="44"
                placeholder="MESSAGE"
                style="height: 150px"
              />
            </div>
            <div class="button-holder send">
              <SendButton class="done" @click="submit" />
            </div>
          </div>
          <div v-if="sent" class="modal-footer modal-footer-success">
            <ThankYouSVG class="success-heading" />
            <span class="success-text"> Your message has been sent.<br />Check your inbox shortly. </span>
            <div class="button-holder">
              <DoneButton class="done" @click="$emit('close')" />
            </div>
            <!-- <button class="button btn-continue" @click="success"></button> -->
          </div>
          <div v-else class="modal-footer">
            <button class="button btn-cancel" @click="$emit('close')"></button>
            <button ref="submitButton" type="submit" class="button" :class="`btn-${type}`" @click="submit"></button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import SendButton from '@/assets/svg/send.svg';
import DoneButton from '@/assets/svg/done.svg';
import ThankYouSVG from '@/assets/svg/thankyou_modal.svg';
import CloseButton from '@/assets/svg/close.svg';
import axios from 'axios';
import TikTokUpload from '@/components/TikTokUpload.vue';

export default {
  components: {
    SendButton,
    DoneButton,
    ThankYouSVG,
    TikTokUpload,
    CloseButton,
  },
  props: {
    instagram: Boolean,
    assetID: String,
    type: String,
  },
  data() {
    return {
      emailAddress: null,
      phoneNumber: null,
      message: null,
      sent: false,
    };
  },
  methods: {
    success() {
      this.$emit('close');
      this.$emit('success');
      this.sent = false;
    },
    submit() {
      if (this.type === 'email' || this.type === 'instagram') this.submitEmail();
      else if (this.type === 'sms') this.submitSMS();
      else if (this.type === 'tiktok') this.submitTiktok();
    },
    submitTiktok() {
      this.$emit('tiktokSuccess');
      this.sent = true;
    },
    submitEmail() {
      const regex = /^([\w-]+(?:\.[\w-]+)*)(\+[\w-]+)?@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      if (!this.emailAddress || !regex.test(this.emailAddress)) return;
      this.$refs.submitButton.disabled = true;
      const body = {
        assetId: this.assetID,
        emailAddresses: [this.emailAddress],
        message: this.message,
        isInstagram: this.instagram,
      };
      if (process.env.VUE_APP_MODE === 'kiosk') {
        axios
          .post('/emails', body)
          .then(() => {
            this.sent = true;
            this.$refs.submitButton.disabled = false;
          })
          .catch((error) => {
            console.error(error);
            this.$refs.submitButton.disabled = false;
          });
      } else {
        axios
          .post(`${process.env.VUE_APP_ONLINE_URL}/emails`, body)
          .then(() => {
            this.sent = true;
            this.$refs.submitButton.disabled = false;
          })
          .catch((e) => {
            console.error(e);
            this.$refs.submitButton.disabled = false;
          });
      }
    },
    submitSMS() {
      const regex = /^[0-9]{3}[0-9]{3}[0-9]{4}$/;
      if (!this.phoneNumber || !regex.test(this.phoneNumber)) return;
      this.$refs.submitButton.disabled = true;
      const body = {
        assetId: this.assetID,
        phoneNumbers: [this.phoneNumber.toString()],
        message: this.message,
      };
      if (process.env.VUE_APP_MODE === 'kiosk') {
        axios
          .post('/sms', body)
          .then(() => {
            this.sent = true;
            this.$refs.submitButton.disabled = false;
          })
          .catch((e) => {
            console.error(e);
            this.$refs.submitButton.disabled = false;
          });
      } else {
        axios
          .post(`${process.env.VUE_APP_ONLINE_URL}/sms`, body)
          .then(() => {
            this.sent = true;
            this.$refs.submitButton.disabled = false;
          })
          .catch((e) => {
            console.error(e);
            this.$refs.submitButton.disabled = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  .icon {
    width: 30px;
    height: 24px;
  }
}

.modal-body {
  .field + .field {
    // margin-top: 10px;
  }
  label {
    display: block;
  }
  input {
    font-size: 16px;
    font-family: inherit;
    outline: none;
    border: none;
    padding: 6px 0;
    width: 100%;
  }

  textarea {
    font-family: inherit;
    font-size: 16px;
    padding: 12px;
    box-sizing: border-box;
    outline: none;
    border: none;
    height: 120px;
    width: 100%;
    resize: none;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  height: 100%;
  //padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  width: 100%;
  margin: 0px auto;
  padding: 30px 30px 22px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  min-height: 464px;

  @include md-tablet {
    width: 420px;
  }
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-body {
  margin: 20px 0;
}
.x-cancel {
  position: absolute;
  /* top: 25%; */
  right: 10%;
  width: 30px;
  height: 30px;
  font-size: 30px;
}
.success-heading {
  // margin-top: 107px;
  width: 222.56px;
  height: 59.94px;
}
.success-text {
  color: rgb(0, 0, 0);
  font-size: 18px;
  width: 162px;
  height: 96px;
  flex-shrink: 0;
  font-weight: 200;
  text-transform: uppercase;
  margin-top: 33px;
}
.button-holder {
  display: flex;
  justify-content: flex-end;
  margin-top: 160px;
  &.send {
    margin-top: 28px;
  }
}
.done {
  border: none;
  width: 111.44px;
  height: 37.423px;
  flex-shrink: 0;
}
.modal-footer {
  .button {
    color: inherit;
    font-family: inherit;
    font-size: 16px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
    transition: all 200ms;
    margin-bottom: 8px;

    &::before {
      content: '';
    }
    @include md-tablet {
      width: auto;
    }
  }
  .btn-cancel {
    &::before {
      content: 'cancel';
    }
  }
  .btn-continue {
    margin: 10px auto 0;
    &::before {
      content: 'continue';
    }
  }
  @include md-tablet {
    display: flex;
    justify-content: space-between;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 300ms;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
</style>
