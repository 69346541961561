<template>
  <div class="share-modal">
    <div class="modal-mask" @click="$emit('close')" />
    <div class="input-wrapper">
      <input
        type="email"
        v-model="emailAddress"
        id="email"
        value=""
        placeholder="email address"
        v-if="type === 'email'"
      />
      <input
        type="email"
        v-model="phoneNumber"
        id="tel"
        value=""
        placeholder="phone number"
        pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
        v-if="type === 'sms'"
      />
    </div>
    <button type="submit" class="default-button" @click="submitEntry">submit</button>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

export default {
  props: { assetID: String, type: String },
  data() {
    return {
      emailAddress: null,
      phoneNumber: null,
      message: 'Sign me up for the big leagues. Thanks for the memories, #ChevyShareStation. #ChevyBaseball',
      sent: false,
    };
  },
  methods: {
    submitEntry() {
      if (this.type === 'email') {
        this.submitEmail();
      }
      if (this.type === 'sms') {
        this.submitSMS();
      }
    },

    success() {
      this.$emit('close');
      this.$emit('success');
    },

    submitEmail() {
      const body = {
        assetId: this.assetID,
        emailAddresses: [this.emailAddress],
        message: this.message,
        isInstagram: this.instagram,
      };
      if (process.env.VUE_APP_MODE === 'kiosk') {
        axios
          .post('/emails', body)
          .then(() => {
            this.success();
          })
          .catch((error) => console.error(error));
      } else {
        axios.post(`${process.env.VUE_APP_ONLINE_URL}/emails`, body).then(() => {
          this.success();
        });
      }
    },
    submitSMS() {
      const body = { assetId: this.assetID, phoneNumbers: [this.phoneNumber.toString()], message: this.message };
      if (process.env.VUE_APP_MODE === 'kiosk') {
        axios.post('/sms', body).then(() => {
          this.success();
        });
      } else {
        axios.post(`${process.env.VUE_APP_ONLINE_URL}/sms`, body).then(() => {
          this.success();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input[type='email'],
input[type='tel'] {
  width: 512px;
  padding: 15px;
  font-size: 32px;
  outline: none;
  border: 4px solid #b88404;
  color: black;
  font-family: LouisGlobal2 !important;
  font-weight: bold;
  &::placeholder {
    text-transform: uppercase;
    text-align: center;
    color: #b88404;
  }

  @include sm-mobile {
    margin: 0 15px;
    width: 100%;
    max-width: calc(100vw - 30px);
    box-sizing: border-box;
  }
}

.input-wrapper {
  z-index: 9999;
}

.modal-mask {
  z-index: 9998;
  width: 100%;
  height: 100%;
}

.share-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.default-button {
  background: #b88404;
  color: white;
  font-size: 24px;
  padding: 12px 40px;
  text-transform: uppercase;
  border: none;
  outline: none;
  margin-top: 30px;
  z-index: 9999;
}

.modal-mask {
  font-family: 'brandon-grotesque';
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
