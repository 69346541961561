<template>
  <div>
    <div class="share-bg" />
    <div class="share-card">
      <!-- <div class="middle">
        <div class="grid-left"> -->
      <!-- <div class="card-item-failed" v-if="failedAsset">
        <div class="item-image">Coming Soon</div>
        <div class="item-footer" v-if="showFooter"></div>
      </div> -->
      <component
        :is="cardComponent"
        :orientation="getOrientation"
        :imageURL="imageURL"
        :flipState="cardFlipState"
        :backUrl="getBackUrl"
      />
      <h1>Save your profile pic.</h1>
      <button class="download-btn" @click="downloadBtnPress">
        <svg xmlns="http://www.w3.org/2000/svg" width="245" height="43" viewBox="0 0 245 43" fill="none">
          <path
            d="M241.447 0.875H3.6783C2.02653 0.875 0.6875 1.94952 0.6875 3.275V40.475C0.6875 41.8005 2.02653 42.875 3.6783 42.875H241.447C243.098 42.875 244.438 41.8005 244.438 40.475V3.275C244.438 1.94952 243.098 0.875 241.447 0.875Z"
            fill="white"
          />
        </svg>
        <span>Download</span>
      </button>
      <!-- </div> -->
      <!-- <div class="grid-right">
          <div class="title"></div>
          <slot name="social"></slot>
        </div> -->
      <!-- </div>
      <div class="logo" v-if="$route.params.slug !== 'jordan24'" /> -->
    </div>
  </div>
</template>

<script>
import CardBase from '@/components/CardBase.vue';
import CardFlip from '@/components/CardFlip.vue';
import CardFlip2 from '@/components/CardFlip2.vue';
import VueQRcode from 'vue-qrcode';

export default {
  name: 'ShareCard',
  components: {
    CardBase,
    CardFlip,
    CardFlip2,
    VueQRcode,
  },
  props: {
    imageURL: String,
    assetID: String,
    failedAsset: Boolean,
    metadata: Object,
  },
  data() {
    return {
      cardFlipState: false,
    };
  },
  computed: {
    getBackUrl() {
      if (this.metadata && this.metadata.backUrl) {
        return this.metadata.backUrl;
      }

      return 'none';
    },
    getOrientation() {
      if (this.metadata && this.metadata.orientation) {
        return this.metadata.orientation;
      }

      return 'portrait';
    },
    cardComponent() {
      if (this.metadata && this.metadata.componentType === 'cardFlip') {
        return 'CardFlip';
      }
      if (this.metadata && this.metadata.componentType === 'cardFlip2') {
        return 'CardFlip2';
      }
      return 'CardBase';
    },
  },
  methods: {
    changeFlipState() {
      this.cardFlipState = !this.cardFlipState;
    },
    async getFile() {
      let file;
      await fetch(this.imageURL)
        .then((response) => response.blob())
        .then((blob) => {
          file = new File([blob], 'porscheacademy-profile.png', { type: 'image/png' });
        });
      return file;
    },
    downloadWithLink() {
      const a = document.createElement('a');
      a.href = this.imageURL;
      a.download = 'porscheacademy-profile.png';
      a.click();
    },
    async downloadBtnPress() {
      const isiOS =
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      if (isiOS) {
        // try navigator.share first
        try {
          await navigator.share({
            // eslint-disable-next-line no-restricted-globals
            url: location.href,
            files: [await this.getFile()],
          });
        } catch (err) {
          if (err.toString().includes('AbortError')) {
            // do nothing
          } else {
            // fallback to download image
            this.downloadWithLink();
          }
        }
      } else {
        // if useragent is not ios, download image
        this.downloadWithLink();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('/fonts/PorscheNext/stylesheet.css');

.share-card {
  width: 100%;
  min-height: calc(100svh - 45px - 28px);
  box-sizing: border-box;
  padding: 50px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.share-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
::v-deep .grid-card img {
  margin: 0 auto;
  max-width: 250px;
}

h1 {
  width: 243px;
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Porsche Next';
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px; /* 115% */
  margin: 50px 0 32px 0;
  text-align: left;
}

.download-btn {
  width: 243px;
  position: relative;
  display: block;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin: 0 auto;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #010205;
    text-align: center;
    font-family: 'Porsche Next';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

// ...

.top {
  margin-top: 47px;
  @include sm-mobile {
    margin-bottom: 30px;
  }
}

.middle {
  padding: 20px;
  display: grid;
  align-items: center;
  grid-template-columns: 1.5fr 2fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'flip .'
    'left right';

  @include sm-mobile {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      'flip'
      'left'
      'right';
    border: none;
  }

  .grid-left {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-right: 20px;
    grid-area: left;

    @include sm-mobile {
      padding-right: 0;
    }
  }
  .grid-right {
    grid-area: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;

    .title {
      font-size: 24px;
      text-align: center;

      &::before {
        content: 'Select';
        text-transform: uppercase;
        font-size: 2em;
        line-height: 1.2;
        font-weight: 900;
        white-space: pre-wrap;
        display: block;
      }
      &::after {
        content: 'one of the following';
        text-transform: uppercase;
        white-space: pre-wrap;
        display: block;
      }
      @include md-tablet {
        font-size: 30px;
        text-align: left;
      }
    }

    @include md-tablet {
      margin-left: 20px;
    }
  }
}

.card-item-failed {
  background-color: darkgray !important;
  width: 100%;
  color: black;
  //height: 100%;
  //max-width: 260px;

  item-image {
    width: 100%;
  }
  .item-image {
    background-color: white !important;
    background-position: center center !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
  }
  .item-image::before {
    content: '';
    display: block;
    width: 1px;
    height: 0;
    padding-bottom: calc(100% / (16 / 9));
  }

  .item-image::after {
    content: '';
    display: block;
  }

  .item-footer {
    width: 100%;
    padding: 0 0 0 13px;
    box-sizing: border-box;
    height: 60px;
    background-color: white;
    display: flex;
    justify-content: space-between;

    align-items: center;

    .item-description {
      font-size: 14px;
      font-family: 'brandon-grotesque';
      font-weight: 300;
    }

    .item-qr {
      width: 50px;
      height: 50px;

      & > * {
        width: 100%;
        height: auto;
      }
    }
  }
}

.qr-code {
  margin: 45px auto 0 0;
  width: 120px;
  height: 120px;
  & > * {
    width: 100%;
    height: auto;
  }
}
</style>
