<template>
  <div class="privacy">
    <div class="close" @click="close"><IconX style="stroke: black" /></div>
    <iframe v-if="isKiosk" class="frame kiosk-frame" src="/static/policy.html" frameborder="0" sandbox=""></iframe>
    <iframe
      v-else-if="$route.params.slug === 'brightdropphotoop'"
      class="frame"
      src="/static/US_Consumer_Privacy_Statement_January_2023.html"
      frameborder="0"
      sandbox=""
    ></iframe>
    <iframe
      v-else
      class="frame"
      src="https://app.termly.io/document/privacy-policy/e0ed9922-a0fb-4797-ae39-068b675ca161"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import IconX from '@/assets/svg/x_icon.svg';

export default {
  components: {
    IconX,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from;
    });
  },
  computed: {
    isKiosk() {
      return process.env.VUE_APP_MODE === 'kiosk';
    },
  },
  methods: {
    close() {
      if (this.previousRoute && this.previousRoute.name) {
        this.$router.replace(this.previousRoute);
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.privacy {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  .close {
    cursor: pointer;
    padding: 20px;
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 2;
  }
  .frame {
    flex-grow: 1;
    overflow: auto;
    margin-top: -70px;
    position: relative;
    z-index: 1;
  }
}
</style>
