<template>
  <section class="grid-card" :class="`grid-card-${orientation}`">
    <div class="grid-card-wrapper">
      <header class="grid-card-header">
        <div class="grid-card-content"></div>
      </header>
      <div class="grid-card-image" :class="`grid-card-image-${orientation}`">
        <video
          v-if="
            isVideo && $route.params.slug !== '2021buickkaraoke' && $route.params.slug !== '2021buickkaraokevolleyball'
          "
          class="msu-video"
          :src="imageURL"
          autoplay
          muted
          playsinline
          loop
          :controls="showControls"
          ref="video"
        ></video>
        <!-- <video
          v-if="
            isVideo &&
            ($route.params.slug === '2021buickkaraoke' || $route.params.slug === '2021buickkaraokevolleyball')
          "
          class="msu-video"
          :src="imageURL"
          controls
          muted
          playsinline
          loop
        ></video> -->
        <img v-else :src="imageURL" :class="`img-${orientation}`" />
      </div>
      <footer class="grid-card-footer">
        <div class="grid-card-branding"></div>
        <div class="grid-card-logo"></div>
      </footer>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CardBase',
  props: {
    imageURL: String,
    orientation: String,
  },
  // mounted() {
  //   if (this.$route.params.slug === 'chevycma2022' && this.$route.name === 'online-share') {
  //     this.$refs.video.muted = false;
  //   }
  // },
  computed: {
    isVideo() {
      return this.imageURL && this.imageURL.toLowerCase().includes('.mp4');
    },
    showControls() {
      const route = this.$route.params.slug.toLowerCase();

      return (route === 'chevycma2022' || route === 'chevymlballstar2022') && this.$route.name === 'online-share';
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-card {
  margin: 0 30px;

  img,
  video {
    display: block;
    max-width: 100%;
  }
  &-footer {
    display: flex;
  }
  &-qr {
    margin: 15px auto 0;
    width: 115px;
  }
}

.grid-card-landscape {
  margin: 0;
}
</style>
