import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import createRouter from './router';
import store from './store';

Vue.use(VueMeta);

Vue.config.productionTip = false;

// https://vuejs.org/v2/guide/components-registration.html

const requireComponent = require.context('./event-component', true, /[A-Z]\w+\.(vue|js)$/);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName
    .toLowerCase()
    .split('/')
    .slice(-2)
    .join()
    .replace(',', '')
    .replace(/\.\w+$/, '');
  Vue.component(componentName, componentConfig.default || componentConfig);
});

const app = {
  router: createRouter(),
  store,
  head: {},
  render: (h) => h(App),
};

export default function createApp() {
  return app;
}

if (!process.env.VAPPER_TARGET) {
  new Vue(app).$mount('#app');
}
