<template>
  <div class="card-item">
    <div class="item-image" :style="{ background: backgroundURL }">
      <video :src="imageURL" autoplay muted playsinline loop v-if="isVideo"></video>
    </div>
    <div class="item-footer" v-if="showFooter"></div>
  </div>
</template>

<script>
export default {
  name: 'CardPortrait',
  props: {
    showFooter: Boolean,
    imageURL: String,
  },
  computed: {
    isVideo() {
      if (this.imageURL != null) {
        return this.imageURL.toLowerCase().includes('.mp4');
      }
      return false;
    },
    backgroundURL() {
      if (!this.isVideo) {
        return `url(${this.imageURL})`;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-item {
  // background-color: white !important;
  width: 100%;
  // height: 100%;
  max-width: 250px;
  margin: 0 auto;
  item-image {
    width: 100%;
  }
  .item-image {
    position: relative;
    //background-color: white !important;
    background-position: center center !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
  }
  .item-image::before {
    content: '';
    display: block;
    width: 1px;
    height: 0;
    padding-bottom: calc(100% / (3 / 4));
  }

  .item-footer {
    width: 100%;
    padding: 0 0 0 13px;
    box-sizing: border-box;
    height: 60px;
    //background-color: white;
    display: flex;
    justify-content: space-between;

    align-items: center;

    .item-description {
      font-size: 14px;
      font-family: 'brandon-grotesque';
      font-weight: 300;
    }

    .item-qr {
      width: 50px;
      height: 50px;

      & > * {
        width: 100%;
        height: auto;
      }
    }
  }
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  backface-visibility: hidden;
}
</style>
