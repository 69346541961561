<template>
  <div class="thanks">
    <div></div>
    <div class="content">
      <div v-if="$route.params.slug !== 'msutunnel'" class="after-thanks"></div>
    </div>
    <div class="logo" />
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped>
.thanks {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  letter-spacing: 2.5px;
  font-family: 'Buick Futura ND';
  flex-direction: column;
  justify-content: space-between;
}
.content {
  font-size: 30px;
  text-align: center;

  &::before {
    content: 'Thank You';
    display: block;
    font-size: 1.75em;
    font-weight: 300;
  }
  &::after {
    content: '';
  }
  .after-thanks {
    display: none;
  }
}
</style>
