<template>
  <transition appear name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :class="[`type-${type}`, sent ? 'sent' : '']">
          <div class="modal-header">
            <div class="icon"></div>
            <h2 v-if="type === 'sms' && !sent">Share photo via text</h2>
            <h2 v-if="type === 'email' && !sent">Share photo via email</h2>
            <h2 v-if="sent"></h2>
          </div>

          <div v-if="!sent" class="modal-body">
            <div v-if="type === 'email'" class="field email">
              <label for="email">Email</label>
              <input
                type="email"
                id="email"
                name="recipient"
                placeholder="What is the recipient's email address?"
                v-model="emailAddress"
              />
            </div>
            <div v-else-if="type === 'sms'" class="field sms">
              <label for="sms">Phone</label>
              <input
                type="tel"
                id="sms"
                name="recipient"
                v-model="phoneNumber"
                placeholder="What is the recipient's phone number?"
                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
              />
            </div>
            <div class="field message">
              <label for="messsage">Message</label>
              <textarea id="message" v-model="message" rows="10" cols="44" placeholder="What do you want to say?" />
            </div>
          </div>
          <!-- <div v-else-if="!sent && $route.params.slug === 'msutunnel'" class="modal-body">
            <label class="msg" for="messsage">Message</label>
            <textarea id="msg" value="" v-model="message" rows="10" cols="44" />
            <span class="modal-input" v-if="type === 'email'">
              <label class="email" for="email">Email</label>
              <input type="email" id="email" value="" v-model="emailAddress" />
            </span>
            <span class="modal-input" v-else>
              <label class="email" for="sms">Phone Number</label>
              <input type="tel" id="sms" v-model="phoneNumber" value="" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" />
            </span>
          </div> -->

          <div v-if="sent" class="modal-footer modal-footer-success">
            <button class="button btn-continue" @click="success"></button>
          </div>
          <div v-else class="modal-footer">
            <button ref="submitButton" type="submit" class="button send" :class="`btn-${type}`" @click="submit">
              Send
            </button>
            <button class="button btn-cancel" @click="$emit('close')">Cancel</button>
          </div>

          <!-- <div v-else-if="!sent && $route.params.slug === 'msutunnel'" class="modal-footer">
            <button class="modal-cancel-button" @click="$emit('close')">cancel</button>
            <button
              ref="submitButton"
              type="submit"
              class="modal-default-button"
              @click="submitEmail"
              v-if="type === 'email'"
            >
              send
            </button>
            <button ref="submitButton" type="submit" class="modal-default-button" @click="submitSMS" v-else>send</button>
          </div> -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

export default {
  props: {
    instagram: Boolean,
    assetID: String,
    type: String,
  },
  data() {
    return {
      emailAddress: null,
      phoneNumber: null,
      message: null,
      sent: false,
    };
  },
  methods: {
    success() {
      this.$emit('close');
      this.$emit('success');
      this.sent = false;
    },
    submit() {
      if (this.type === 'email') this.submitEmail();
      else if (this.type === 'sms') this.submitSMS();
    },
    submitEmail() {
      const regex = /^([\w-]+(?:\.[\w-]+)*)(\+[\w-]+)?@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      if (!this.emailAddress || !regex.test(this.emailAddress)) return;
      this.$refs.submitButton.disabled = true;
      const body = {
        assetId: this.assetID,
        emailAddresses: [this.emailAddress],
        message: this.message,
        isInstagram: this.instagram,
      };
      if (process.env.VUE_APP_MODE === 'kiosk') {
        axios
          .post('/emails', body)
          .then(() => {
            this.sent = true;
            this.$refs.submitButton.disabled = false;
          })
          .catch((error) => {
            console.error(error);
            this.$refs.submitButton.disabled = false;
          });
      } else {
        axios
          .post(`${process.env.VUE_APP_ONLINE_URL}/emails`, body)
          .then(() => {
            this.sent = true;
            this.$refs.submitButton.disabled = false;
          })
          .catch((e) => {
            console.error(e);
            this.$refs.submitButton.disabled = false;
          });
      }
    },
    submitSMS() {
      const regex = /^[0-9]{3}[0-9]{3}[0-9]{4}$/;
      if (!this.phoneNumber || !regex.test(this.phoneNumber)) return;
      this.$refs.submitButton.disabled = true;
      const body = {
        assetId: this.assetID,
        phoneNumbers: [this.phoneNumber.toString()],
        message: this.message,
      };
      if (process.env.VUE_APP_MODE === 'kiosk') {
        axios
          .post('/sms', body)
          .then(() => {
            this.sent = true;
            this.$refs.submitButton.disabled = false;
          })
          .catch((e) => {
            console.error(e);
            this.$refs.submitButton.disabled = false;
          });
      } else {
        axios
          .post(`${process.env.VUE_APP_ONLINE_URL}/sms`, body)
          .then(() => {
            this.sent = true;
            this.$refs.submitButton.disabled = false;
          })
          .catch((e) => {
            console.error(e);
            this.$refs.submitButton.disabled = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  .icon {
    width: 30px;
    height: 24px;
  }
}

.modal-body {
  .field + .field {
    margin-top: 30px;
  }
  label {
    display: block;
    margin-bottom: 15px;
  }
  input {
    font-size: 16px;
    font-family: inherit;
    outline: none;
    border: 0px;
    border-bottom: 1px solid #003f6e;
    width: 100%;
    background: #f3f3f3;
    padding-bottom: 10px;
    color: #003f6e;
    &::placeholder {
      font-family: 'Avant Garde Book';
      color: #003f6e;
      opacity: 0.5;
    }
  }

  textarea {
    font-family: inherit;
    font-size: 16px;
    padding: 12px;
    box-sizing: border-box;
    outline: none;
    height: 120px;
    width: 100%;
    resize: none;
    border: 1px solid #003f6e;
    background: #f3f3f3;
    color: #003f6e;

    &::placeholder {
      font-family: 'Avant Garde Book';
      color: #003f6e;
      opacity: 0.5;
    }
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  height: 100%;
  //padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  width: 100%;
  margin: 0px auto;
  padding: 30px 30px 22px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;

  @include md-tablet {
    width: 420px;
  }
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-body {
  margin: 20px 0;
}

.modal-footer {
  .button {
    color: inherit;
    font-family: 'helvetica';
    font-weight: 600;
    font-size: 18px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
    transition: all 200ms;
    margin-bottom: 8px;
    border-radius: 38px;
    &::before {
      content: 'send';
    }
    @include md-tablet {
      width: auto;
    }
  }
  .send {
    border: 1px solid #003f6e;
    padding: 15px 80px;
  }
  .btn-cancel {
    margin-right: 50px;
    &::before {
      content: 'cancel';
    }
  }
  .btn-continue {
    margin: 10px auto 0;
  }
  @include md-tablet {
    display: flex;
    justify-content: space-between;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 300ms;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
</style>
