<template>
  <div id="app" :class="`mode-${$options.appMode} ${shareClass}`">
    <div class="kiosk-navigation" @click="goBack" v-if="showKioskNavigation">
      <Carat class="navigation-carat" />
      <span class="navigation-text">Back</span>
    </div>
    <header class="app-header">
      <div class="content"></div>
      <InsiderLogo class="logo" v-if="$route.path.includes('f5insider') || $route.path.includes('f5ai')" />
    </header>

    <router-view class="page" />
    <div class="logo-container">
      <JordanLogo class="logo jordan" v-if="$route.path.includes('jordan24')" />
    </div>

    <footer class="app-footer">
      <div class="content"></div>
      <PrivacyPolicy v-if="$route.name !== 'policy'" />
    </footer>
  </div>
</template>

<script>
// import axios from 'axios';
import Carat from '@/assets/svg/carat.svg';
import InsiderLogo from '@/assets/svg/insider.svg';
import JordanLogo from '@/assets/svg/jordan.svg';
import axios from 'axios';
import PrivacyPolicy from './components/PrivacyPolicy.vue';

export default {
  components: {
    PrivacyPolicy,
    Carat,
    InsiderLogo,
    JordanLogo,
  },
  appMode: process.env.VUE_APP_MODE,
  created() {
    if (process.env.VUE_APP_MODE === 'online' && this.$route.path === '/') {
      window.location.href = 'https://social-gen.com';
    } else if (this.$route.params.slug === 'braze24') {
      window.location.href = `https://braze2024.com/${this.$route.params.assetID}`;
    } else if (!process.server && this.$route.params.slug) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = `/themes/${this.$route.params.slug}/theme.css`;
      document.head.appendChild(link);
    }
  },
  mounted() {
    if (this.$route.params.slug === 'forddriveraptor' || this.$route.params.slug === 'forddrivemustang') {
      let activationId = 'c920c34f-3f37-4909-bbc1-2b153437098a';
      if (this.$route.params.slug === 'forddriveraptor') {
        activationId = '1b1a7de1-89e2-45cc-bfe8-4d7261fe9966';
      }
      const data = JSON.stringify({
        activationId,
        action: 'view-email',
        assetId: this.$route.params.assetID,
        slug: this.$route.params.slug,
      });

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.insights.experienceclutch.com/api/socialgenRecord',
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      };

      axios
        .request(config)
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // window.location.href = `https://assets.prod-3081-1.clutchpreview.com/public/${this.$route.params.assetID}.mp4`;
          window.location.href = `https://api.social-gen.com/custom/employee-drive-event/${this.$route.params.assetID}?slug=${this.$route.params.slug}&activationId=${activationId}`;
          setTimeout(() => {
            window.close();
          }, 1500);
          document.body.innerHTML = '';
        });
    }
  },
  computed: {
    showKioskNavigation() {
      return process.env.VUE_APP_MODE === 'kiosk' && (this.$route.params.assetID || this.$route.name === 'policy');
    },
    shareClass() {
      return this.$route.params.assetID ? 'share' : 'kiosk';
    },
  },
  methods: {
    goBack() {
      if (this.previousRoute) {
        this.$router.push(this.previousRoute);
        delete this.previousRoute;
      } else {
        this.$router.push({ name: 'kiosk', params: { slug: this.$route.params.slug } });
      }
    },
  },
  watch: {
    $route(to, from) {
      if (from.name !== 'policy') {
        this.previousRoute = from;
      }
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/_base.scss';
html,
body {
  overscroll-behavior: none;
}
body {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: center;
  background-size: cover;
  overflow-x: hidden !important;
  font-family: 'brandon-grotesque';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100%;

  @include sm-mobile {
    overflow: auto;
  }
}

#app {
  box-sizing: border-box;
  font-family: 'brandon-grotesque';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &.mode-kiosk {
    overflow: hidden;
  }
}

.kiosk-navigation {
  padding: 30px;
  position: absolute;

  .navigation-carat {
    transform: rotate(90deg);
    height: 12px;
  }

  .navigation-text {
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
  }
}
.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.app-header,
.app-footer {
  text-align: center;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 45px;
}
.logo {
  margin-top: 29.13px;
  width: 50px;
  height: 50px;

  &.jordan {
    // margin-top: 0px;
    width: 58.74px;
    height: 54.832px;
  }
}
</style>
