var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"share-social"},[_c('div',{staticClass:"share-icons"},[(
        _vm.$route.params.slug !== 'msutunnel' &&
        _vm.$route.params.slug !== '2021buickkaraoke' &&
        _vm.$route.params.slug !== '2021buickkaraokevolleyball'
      )?_c('button',{staticClass:"social-icon icon-email",on:{"click":function($event){return _vm.$emit('shareEmail', 'shareEmail')}}}):_c('button',{staticClass:"social-icon icon-sms",on:{"click":function($event){return _vm.$emit('shareSMS', 'shareSMS')}}}),(
        _vm.$route.params.slug !== 'msutunnel' &&
        _vm.$route.params.slug !== '2021buickkaraoke' &&
        _vm.$route.params.slug !== '2021buickkaraokevolleyball'
      )?_c('button',{staticClass:"social-icon icon-sms",on:{"click":function($event){return _vm.$emit('shareSMS', 'shareSMS')}}}):_c('button',{staticClass:"social-icon icon-email",on:{"click":function($event){return _vm.$emit('shareEmail', 'shareEmail')}}}),(!_vm.isKiosk && _vm.$route.params.slug !== 'jordan24')?_c('button',{staticClass:"social-icon icon-facebook",on:{"click":_vm.facebookLink}}):_vm._e(),(!_vm.isKiosk && _vm.$route.params.slug !== 'jordan24')?_c('button',{staticClass:"social-icon icon-twitter",on:{"click":_vm.twitterLink}}):_vm._e(),(!_vm.isKiosk && _vm.$route.params.slug === 'jordan24')?_c('button',{staticClass:"social-icon icon-download",on:{"click":_vm.share}}):_vm._e(),(
        !_vm.isKiosk &&
        _vm.$route.params.slug !== 'jackierobinson' &&
        _vm.$route.params.slug !== 'chevytc2022det' &&
        _vm.$route.params.slug !== 'chevycomicbook22det'
      )?_c('button',{staticClass:"social-icon icon-instagram",on:{"click":function($event){return _vm.$emit('shareInsta', 'shareInsta')}}}):_vm._e(),(
        !_vm.isKiosk &&
        (_vm.$route.params.slug === 'essilortransitions' ||
          _vm.$route.params.slug === 'brightdropphotoop' ||
          _vm.$route.params.slug === 'flywithakamai' ||
          _vm.$route.params.slug === 'biogen2023')
      )?_c('button',{staticClass:"social-icon icon-linkedin",on:{"click":_vm.shareOnLinkedin}}):_vm._e(),((_vm.$route.params.slug === 'chevycma2022' || _vm.$route.params.slug === 'chevymlballstar2022') && !_vm.isKiosk)?_c('button',{staticClass:"social-icon icon-tiktok",on:{"click":function($event){return _vm.$emit('shareTiktok', 'shareTiktok')}}}):_vm._e(),(
        _vm.$route.params.slug === 'essilorlenswithfriends' ||
        _vm.$route.params.slug === 'essilorvogue' ||
        _vm.$route.params.slug === 'essiloroakley' ||
        _vm.$route.params.slug === 'essilortransitions' ||
        _vm.$route.params.slug === 'essilorrayban' ||
        _vm.$route.params.slug === 'toyota23'
      )?_c('a',{staticClass:"social-icon icon-download",attrs:{"href":_vm.s3Link,"download":""},on:{"click":function($event){return _vm.$emit('shareDownload', 'shareDownload')}}}):_vm._e(),(_vm.isKiosk)?_c('div',{staticClass:"qr-code"},[_c('VueQRcode',{attrs:{"scale":8,"maskPattern":4,"width":12,"value":_vm.shareURL}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }