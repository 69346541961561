import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showShareEmail: false,
    showShareInstagram: false,
    showShareThankYou: false,
  },
  getters: {
    getShareEmail: (state) => state.showShareModal,
    getShareInstagram: (state) => state.showShareModal,
    getShareThankYou: (state) => state.showShareThankYou,
  },
  mutations: {
    setShareEmail(state, value) {
      state.showShareEmail = value;
    },
    setShareInstagram(state, value) {
      state.showShareInstagram = value;
    },
    setShareThankYou(state, value) {
      state.showShareThankYou = value;
    },
  },
  actions: {},
  modules: {},
});
