<template>
  <div class="share-card">
    <component :is="cardComponent" :orientation="getOrientation" :imageURL="imageURL" :flipState="cardFlipState" />
  </div>
</template>

<script>
import CardBase from '@/components/CardBase.vue';
import CardFlip from '@/components/CardFlip.vue';
import VueQRcode from 'vue-qrcode';

export default {
  name: 'ShareCard',
  components: {
    CardBase,
    CardFlip,
    VueQRcode,
  },
  props: {
    imageURL: String,
    assetID: String,
    failedAsset: Boolean,
    metadata: Object,
  },
  data() {
    return {
      cardFlipState: false,
    };
  },
  computed: {
    getOrientation() {
      if (this.metadata && this.metadata.orientation) {
        return this.metadata.orientation;
      }

      return 'portrait';
    },
    cardComponent() {
      if (this.metadata && this.metadata.componentType === 'cardFlip') {
        return 'CardFlip';
      }
      return 'CardBase';
    },
  },
  methods: {
    changeFlipState() {
      this.cardFlipState = !this.cardFlipState;
    },
  },
  mounted() {
    document.body.style.backgroundColor = 'black';
  },
};
</script>

<style lang="scss" scoped>
.share-card {
  width: 80%;
  max-width: 512px;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
