<template>
  <div class="share-social">
    <VueQRcode class="qr-code" :scale="3" :maskPattern="4" :width="12" v-if="isKiosk" :value="shareURL" />
    <div class="share-icons">
      <button
        v-if="
          $route.params.slug !== 'msutunnel' &&
          $route.params.slug !== '2021buickkaraoke' &&
          $route.params.slug !== '2021buickkaraokevolleyball'
        "
        class="social-icon icon-email"
        @click="$emit('shareEmail', 'shareEmail')"
      ></button>
      <button v-else class="social-icon icon-sms" @click="$emit('shareSMS', 'shareSMS')"></button>

      <button
        v-if="
          $route.params.slug !== 'msutunnel' &&
          $route.params.slug !== '2021buickkaraoke' &&
          $route.params.slug !== '2021buickkaraokevolleyball'
        "
        class="social-icon icon-sms"
        @click="$emit('shareSMS', 'shareSMS')"
      ></button>
      <button v-else class="social-icon icon-email" @click="$emit('shareEmail', 'shareEmail')"></button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueQRcode from 'vue-qrcode';

export default {
  name: 'ShareSocial',
  props: {},
  components: {
    VueQRcode,
  },
  head() {
    return {
      meta: [
        { property: 'og:title', content: 'This is a test' },
        { property: 'og:image', content: this.s3Link },
        { property: 'twitter:card', content: 'summary_large_image' },
      ],
    };
  },
  async created() {
    const URL = process.env.VUE_APP_MODE === 'kiosk' ? '/' : process.env.VUE_APP_ONLINE_URL;

    const res = await axios.get(`${URL}/assets/${this.assetID}`);
    this.s3Link = res.data.data.s3Link;
  },
  data() {
    return {
      s3Link: null,
      assetID: this.$route.params.assetID,
      shareType: '',
    };
  },
  computed: {
    shareURL() {
      return `${process.env.VUE_APP_FE_APP}/${this.$route.params.slug}/${this.assetID}`;
    },
    isKiosk() {
      return process.env.VUE_APP_MODE === 'kiosk';
    },
  },
  methods: {
    facebookLink() {
      window.location.href = `${process.env.VUE_APP_ONLINE_URL}/facebook/login?assetId=${this.assetID}&s3Link=${this.s3Link}`;
    },

    twitterLink() {
      window.location.href = `${process.env.VUE_APP_ONLINE_URL}/twitter/login?assetId=${this.assetID}&s3Link=${this.s3Link}`;
    },

    instaLink() {
      this.instagram = true;
      this.shareType = 'email';
      this.showModal = true;
    },

    emailLink() {
      this.instagram = false;
      this.shareType = 'email';
      this.showModal = true;
    },
    smsLink() {
      this.shareType = 'sms';
      this.showModal = true;
    },
    showThanks() {
      this.showThankYou = true;
      clearTimeout(this.thankYouTimer);
      setTimeout(() => {
        this.showThankYou = false;
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.share-social {
  width: 100%;
  max-height: 145px;
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;

  .qr-code {
    display: none;
  }

  .share-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 1;

    .social-icon {
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      width: 60px;
      height: 60px;
      border: none;
      outline: none;

      @include md-tablet {
        margin-right: 25px;
      }
    }

    @include md-tablet {
      justify-content: flex-start;
    }
  }
}
</style>
