// TODO add in command line variable for event name for urls

import Vue from 'vue';
import VueRouter from 'vue-router';
import Share from '../views/Share.vue';
import Kiosk from '../views/Kiosk.vue';
import Policy from '../views/Policy.vue';
// import Display from '../views/Display.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:slug/policy',
    name: 'policy',
    component: Policy,
  },
  {
    path: '/:slug/:assetID',
    name: 'online-share',
    component: Share,
    children: [
      {
        path: '*',
        component: Share,
      },
    ],
  },
  {
    path: '/:slug/',
    name: 'kiosk',
    component: Kiosk,
  },
];

export default function createRouter() {
  return new VueRouter({
    mode: 'history',
    routes,
  });
}
