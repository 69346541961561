<template>
  <div class="thanks">
    <div class="content">
      <div v-if="$route.params.slug !== 'msutunnel'" class="after-thanks">USING <span>#TEAMCHEVY</span></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped>
.thanks {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
}
.content {
  font-size: 30px;
  text-align: center;

  &::before {
    content: 'Thank You';
    display: block;
    font-size: 1.75em;
    font-weight: 900;
    text-transform: uppercase;
  }
  &::after {
    content: 'Invite your friends to view your photo';
    text-transform: uppercase;
  }
  .after-thanks {
    display: none;
  }
}
</style>
