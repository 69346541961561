<template>
  <div class="carousel" @touchstart="resetTimer" @mousedown="resetTimer">
    <Swiper ref="swiper" :options="swiperOptions">
      <SwiperSlide v-for="asset in displayedAssets" :key="asset.id">
        <Card :imageURL="asset" :s3Link="asset.s3Link" :assetID="asset.id" @click.native="openCard(asset)" />
      </SwiperSlide>
    </Swiper>
    <div class="navigation">
      <div class="swiper-button-prev" @click="$refs.swiper.$swiper.slidePrev()"></div>
      <div class="swiper-button-next" @click="$refs.swiper.$swiper.slideNext()"></div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
import Card from './GridCard.vue';

export default {
  components: {
    Card,
    Swiper,
    SwiperSlide,
  },
  props: {
    assets: null,
  },
  data() {
    return {
      timer: null,
      swiperOptions: {
        slidesPerView: 3,
        navigation: true,
      },
      prevDisabled: false,
      nextDisabled: false,
    };
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    resetTimer() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$refs.swiper.$swiper.slideTo(0);
      }, 10000);
    },
    openCard(asset) {
      this.$router.push(`/${this.$route.params.slug}/${asset.id}`);
    },
  },
  computed: {
    displayedAssets() {
      if (this.assets != null) {
        return this.assets.slice(0, 6);
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  flex-grow: 1;
  margin: 0 60px;
  position: relative;

  .swiper-button-prev,
  .swiper-button-next {
    color: inherit;
    margin-top: -85px;
  }
  .swiper-button-prev {
    left: -40px;
  }
  .swiper-button-next {
    right: -40px;
  }
}
</style>
