<template>
  <div class="share-social">
    <div class="social-icon" @click="$emit('shareEmail', 'shareEmail')">
      <img :src="`/themes/${$route.params.slug}/email_btn.png`" />
    </div>
    <div class="social-icon" @click="$emit('shareSMS', 'shareSMS')">
      <img :src="`/themes/${$route.params.slug}/sms_btn.png`" />
    </div>

    <div class="social-icon" @click="facebookLink" v-if="!isKiosk">
      <img :src="`/themes/${$route.params.slug}/facebook_btn.png`" />
    </div>

    <div class="social-icon" @click="twitterLink" v-if="!isKiosk">
      <img :src="`/themes/${$route.params.slug}/twitter_btn.png`" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ShareSocial',
  props: {},

  async created() {
    const URL = process.env.VUE_APP_MODE === 'kiosk' ? '/' : process.env.VUE_APP_ONLINE_URL;

    const res = await axios.get(`${URL}/assets/${this.assetID}`);
    this.s3Link = res.data.data.s3Link;
  },
  data() {
    return {
      s3Link: null,
      assetID: this.$route.params.assetID,
      shareType: '',
    };
  },
  computed: {
    isKiosk() {
      return process.env.VUE_APP_MODE === 'kiosk';
    },
  },
  methods: {
    facebookLink() {
      window.location.href = `${process.env.VUE_APP_ONLINE_URL}/facebook/login?assetId=${this.assetID}&s3Link=${this.s3Link}`;
    },

    twitterLink() {
      window.location.href = `${process.env.VUE_APP_ONLINE_URL}/twitter/login?assetId=${this.assetID}&s3Link=${this.s3Link}`;
    },

    instaLink() {
      this.instagram = true;
      this.shareType = 'email';
      this.showModal = true;
    },

    emailLink() {
      this.instagram = false;
      this.shareType = 'email';
      this.showModal = true;
    },
    smsLink() {
      this.shareType = 'sms';
      this.showModal = true;
    },
    showThanks() {
      this.showThankYou = true;
      clearTimeout(this.thankYouTimer);
      setTimeout(() => {
        this.showThankYou = false;
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.share-social {
  width: 100%;
  max-height: 145px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  @include sm-mobile {
    margin: 0;
  }

  .social-icon {
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 96px;
    height: 96px;
    border: none;
    outline: none;
    box-sizing: border-box;
    margin-bottom: 20px;

    @include sm-mobile {
      width: 60px;
      height: 60px;
    }

    &:not(:last-of-type) {
      margin-right: 15px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
