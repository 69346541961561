<template>
  <div class="share-card">
    <div class="top"></div>
    <div class="middle">
      <div class="grid-top">
        <img :src="`/themes/${$route.params.slug}/flip_btn_front.png`" v-if="!cardFlipState" @click="changeFlipState" />
        <img :src="`/themes/${$route.params.slug}/flip_btn_back.png`" v-if="cardFlipState" @click="changeFlipState" />
      </div>
      <div class="grid-left">
        <CardPortraitFlip
          :backURL="getPositionImage"
          :frontURL="imageURL"
          :flipState="cardFlipState"
          v-if="!failedAsset"
        ></CardPortraitFlip>
        <div class="card-item-failed" v-if="failedAsset">
          <div class="item-image">Coming Soon</div>
          <div class="item-footer" v-if="showFooter"></div>
        </div>
      </div>
      <div class="grid-right">
        <img class="select-one" :src="`/themes/${$route.params.slug}/select_one.png`" />
        <slot name="social"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import CardPortraitFlip from '@/components/CardPortraitFlip.vue';

export default {
  name: 'ShareCard',
  components: { CardPortraitFlip },
  props: {
    imageURL: String,
    assetID: String,
    failedAsset: Boolean,
    position: String,
    metadata: Object,
  },
  metaInfo() {
    return {
      title: 'Chevrolet',
      meta: [
        { property: 'og:title', content: 'Chevrolet' },
        {
          property: 'og:description',
          content: 'Sign me up for the big leagues. Thanks for the memories, #ChevyShareStation. #ChevyBaseball',
        },
      ],
    };
  },
  data() {
    return {
      cardFlipState: false,
      positionImage: {
        'right field': `/themes/${this.$route.params.slug}/card_right_field.png`,
        'left field': `/themes/${this.$route.params.slug}/card_left_field.png'`,
        catcher: `/themes/${this.$route.params.slug}/card_catcher.png`,
        pitcher: `/themes/${this.$route.params.slug}/card_pitcher.png`,
        'center field': `/themes/${this.$route.params.slug}/card_center_field.png`,
        '1st base': `/themes/${this.$route.params.slug}/card_first_base.png`,
        shortstop: `/themes/${this.$route.params.slug}/card_shortstop.png`,
        '2nd base': `/themes/${this.$route.params.slug}/card_second_base.png`,
        '3rd base': `/themes/${this.$route.params.slug}/card_third_base.png`,
      },
    };
  },
  methods: {
    changeFlipState() {
      this.cardFlipState = !this.cardFlipState;
    },
  },
  computed: {
    getPositionImage() {
      if (this.metadata != null) {
        const position = this.metadata.position.toLowerCase();
        return this.positionImage[position];
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.share-card {
  width: 100%;
  max-width: 1024px;
  height: 100%;
  margin: 0 auto;
}

.top {
  margin-top: 47px;

  @include sm-mobile {
    margin-bottom: 30px;
  }
}

.middle {
  padding: 18px;
  padding-bottom: 48px;
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'flip .'
    'left right';
  border: 8px solid #b88404;

  @include sm-mobile {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      'flip'
      'left'
      'right';
    border: none;
  }

  .grid-top {
    grid-area: flip;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 45%;
      height: auto;
    }
  }
  .grid-left {
    grid-area: left;
  }
  .grid-right {
    grid-area: right;
    .select-one {
      width: 100%;
      height: auto;

      @include sm-mobile {
        margin-top: 30px;
      }
    }
  }
}

.card-item-failed {
  background-color: #b88404;
  color: white;
  width: 100%;
  height: 100%;
  max-width: 250px;
  margin: 0 auto;
  item-image {
    width: 100%;
  }

  .item-image {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: LouisGlobal2 !important;
  }

  .item-image::before {
    content: '';
    display: block;
    width: 1px;
    height: 0;
    padding-bottom: calc(100% / (3 / 4));
  }

  .item-footer {
    width: 100%;
    padding: 0 0 0 13px;
    box-sizing: border-box;
    height: 60px;
    background-color: white;
    display: flex;
    justify-content: space-between;

    align-items: center;

    .item-description {
      font-size: 14px;
      font-family: 'brandon-grotesque';
      font-weight: 300;
    }

    .item-qr {
      width: 50px;
      height: 50px;

      & > * {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
