<template>
  <transition-group name="list" class="card-grid">
    <!-- <Card v-for="asset in assets" :key="asset.id" :imageURL="asset.s3Link" :assetID="asset.id" /> -->

    <Card v-for="asset in assetGridTest" :key="asset.id" :imageURL="asset" :s3Link="asset.s3Link" :assetID="asset.id" />
    <!--  'asset.metadata.thumbnail"-->
  </transition-group>
</template>

<script>
import Card from './GridCard.vue';

export default {
  name: 'GridGallery',
  components: { Card },
  props: {
    assets: null,
  },
  methods: {},
  computed: {
    assetGridTest() {
      if (this.assets != null) {
        // console.log(this.assets.slice(0, 9));
        return this.assets.slice(0, 9);
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 80px;

  @include md-tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include md-tablet-landscape {
    grid-template-columns: repeat(3, 1fr);
  }
  ::v-deep {
    & > * {
      //background-color: white;
      //background-image: url('../assets/images/desktop_bg_main.png');
      background-size: contain;
      width: 100%;
      height: 100%;
    }

    .item-image {
      width: 100%;
      position: relative;

      video {
        position: absolute;
        top: 0;
        left: 0;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .item-image::before {
      content: '';
      display: block;
      width: 1px;
      height: 0;
      padding-bottom: calc(100% / (3 / 4));
    }

    .item-footer {
      margin-top: 5px;
      width: 100%;
      padding: 12px;
      box-sizing: border-box;
      height: 90px;
      background-color: white;
      display: flex;
      justify-content: space-between;

      align-items: center;

      .item-description {
        font-size: 14px;
        font-family: 'brandon-grotesque';
        font-weight: 300;
      }

      .item-qr {
        width: 70px;
        height: 70px;

        & > * {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .list-enter-active,
  .list-leave-active {
    transition: all 1s;
  }
  .list-enter,
  .list-leave-to {
    opacity: 0;
    transform: translateX(-100%);
  }
  .list-move {
    transition: transform 1s;
  }
}
</style>
