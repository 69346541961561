<template>
  <div class="tiktok-upload">
    <div v-if="success">
      <p class="center">Just a couple more steps to finish posting your TikTok!</p>
      <p>1. <a href="https://www.tiktok.com">Open the TikTok app</a> on your phone</p>
      <p>2. Tap the "Inbox" tab at the bottom</p>
      <img src="@/assets/images/tiktok_step1.png" />
      <p>
        3. Tap the "TikTok Platform" notification near the top<br />
        <span class="grey">(Note: It may take some time for the video to process)</span>
      </p>
      <img src="@/assets/images/tiktok_step2.png" />
      <p>4. {{ shareCopy || 'Edit and share your TikTok!' }}</p>
    </div>
    <p v-else-if="error" class="center">Something went wrong. Please try again.</p>
    <p v-else class="center">Uploading, please wait...</p>
    <div v-if="!success && !error" class="loader" />
  </div>
</template>

<script>
import Axios from 'axios';

export default {
  data() {
    return {
      success: undefined,
      error: '',
    };
  },
  computed: {
    shareCopy() {
      switch (this.$route.params.slug) {
        case 'chevycma2022':
          return "Share your video, don't forget to use the hashtag #ChevyCMA and tag @Breland!";
        case 'chevymlballstar2022':
          return "Share your video, don't forget to use the hashtag #ChevyBaseball";
        default:
          return '';
      }
    },
  },
  async mounted() {
    // Hide download button
    this.$parent.$refs.submitButton.style.display = 'none';
    this.$parent.$refs.submitButton.parentElement.style.display = 'flex';
    this.$parent.$refs.submitButton.parentElement.style.justifyContent = 'center';
    if (this.$route.query.code) {
      // Step 2: Run callback function in backend
      Axios.get(
        `${process.env.VUE_APP_ONLINE_URL}/tiktok/login/callback/${window.location.search}&assetId=${this.$route.params.assetID}`,
        {
          withCredentials: true,
          credentials: 'include',
        },
      )
        .then(async () => {
          // // wait for TikTok to process the video
          // setTimeout(() => {
          this.success = true;
          // }, 5000);
        })
        .catch((err) => {
          this.success = false;
          this.error = err.message;
        });
    } else {
      // Step 1: Redirect to login prompt
      window.location.href = `${process.env.VUE_APP_ONLINE_URL}/tiktok/login?slug=${this.$route.params.slug}&assetId=${this.$route.params.assetID}`;
    }
  },
  beforeDestroy() {
    window.history.pushState({}, document.title, window.location.pathname);
    this.$route.query.code = '';
    this.success = undefined;
    this.error = '';
  },
};
</script>

<style lang="scss" scoped>
.tiktok-upload {
  .center {
    text-align: center;
  }
  .grey {
    color: grey;
  }
  img {
    width: 100%;
  }
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
.loader {
  margin: 40px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.1);
  border-right: 1.1em solid rgba(0, 0, 0, 0.1);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.1);
  border-left: 1.1em solid #000000;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
