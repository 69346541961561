<template>
  <div class="kiosk">
    <!-- <div class="kiosk-background" v-if="isKiosk" /> -->
    <!-- Make into slots  -->
    <div class="title"></div>
    <GridCarousel v-if="galleryStyle === 'carousel'" :assets="assets" />
    <div v-else class="wrapper-scrollable">
      <GridGallery :assets="assets"></GridGallery>
    </div>
    <div class="logo" />
    <!-- <slot name="privacy"></slot> -->
  </div>
</template>

<script>
import axios from 'axios';

import GridGallery from '../components/GridGallery.vue';
import GridCarousel from '../components/GridCarousel.vue';

export default {
  name: 'Kiosk',
  components: {
    GridGallery,
    GridCarousel,
  },
  data() {
    return {
      assets: null,
      refreshTimer: null,
    };
  },

  needSerialize: true,
  async created() {
    clearTimeout(this.refreshTimer);
    this.getAssets();
  },

  beforeDestroy() {
    clearTimeout(this.refreshTimer);
  },

  computed: {
    // isKiosk() {
    //   return process.env.VUE_APP_MODE === 'kiosk';
    // },
    galleryStyle() {
      return 'carousel';
      // switch (this.$route.params.slug) {
      //   case 'asg3dbaseball':
      //   case 'asgTuneUp':
      //     return 'vertical';
      //   default:
      //     return 'carousel';
      // }
    },
  },
  methods: {
    routeClicked: (id) => {
      this.$router.push({ path: `/${this.$route.params.slug}/${id}` });
    },
    async getAssets() {
      // let res;
      if (process.env.VUE_APP_MODE === 'kiosk') {
        await axios.get('/assets').then((res) => {
          this.assets = res.data.data;
        });
      } else {
        await axios.get(`${process.env.VUE_APP_ONLINE_URL}/events/${this.$route.params.slug}/assets`).then((res) => {
          this.assets = res.data.data.slice(0, 9);
        });
      }
      this.refreshTimer = setTimeout(this.getAssets, 10000);
    },
  },
};
</script>

<style lang="scss" scoped>
.kiosk {
  width: 100%;
  max-width: 1024px;
  height: 100%;
  margin: 0 auto;

  .title {
    margin: 30px 0;
    &::before {
      content: 'Select your photo';
      text-align: center;
      text-transform: uppercase;
      font-size: 60px;
      font-weight: 900;
      white-space: pre-wrap;
      display: block;
    }
  }
}

.kiosk-background {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -99;
  top: 0;
  left: 0;
  background: transparent;
  display: hidden;
}

.top {
  width: 100%;
  margin: 91px 0 34px;
  h1 {
    margin: 0;
    font-family: 'brandon-grotesque';
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: white;
    letter-spacing: 2.16px;
    font-size: 36px;
    line-height: 52px;

    @include sm-mobile {
      font-size: 30px;
    }

    @include xl-desktop {
      font-size: 60px;
      letter-spacing: 3.6px;
    }
  }
}

.wrapper-scrollable {
  overflow-y: auto;
  height: 475px;
  padding-bottom: 30px;
  box-sizing: border-box;

  @include lg-desktop {
    padding: 0 20px 30px;
  }
}
</style>
