<template>
  <section class="grid-card">
    <div class="grid-card-wrapper">
      <header class="grid-card-header">
        <div class="grid-card-content"></div>
      </header>
      <div class="grid-card-image">
        <img :src="imageSource" />
      </div>
      <footer class="grid-card-footer">
        <div class="grid-card-branding"></div>
        <div class="grid-card-logo"></div>
      </footer>
    </div>
    <div class="grid-card-qr">
      <VueQRcode :scale="8" :maskPattern="4" :width="12" :value="shareURL" />
    </div>
  </section>
</template>

<script>
import VueQRcode from 'vue-qrcode';

export default {
  name: 'GridCard',
  components: { VueQRcode },
  props: {
    imageURL: String,
    assetID: String,
    s3Link: String,
  },
  computed: {
    imageSource() {
      return this.isVideo ? this.imageURL.metadata.thumbnail : this.s3Link;
    },

    isVideo() {
      return this.imageURL && this.imageURL.s3Link.toLowerCase().includes('.mp4');
    },

    shareURL() {
      return `${process.env.VUE_APP_FE_APP}/${this.$route.params.slug}/${this.assetID}`;
    },
    // backgroundURL() {
    //   if (!this.isVideo) {
    //     return `url(${this.imageURL})`;
    //   }
    //   return null;
    // },
  },
};
</script>

<style lang="scss" scoped>
.grid-card {
  margin: 0 30px;

  img {
    display: block;
    max-width: 100%;
  }
  &-qr {
    margin: 15px auto 0;
    width: 115px;
  }
}

.grid-card-landscape {
  margin: 0;
}
</style>
