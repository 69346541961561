<template>
  <div class="card-flip" :class="{ isFlipped: flipState }">
    <div class="card-flip-inner">
      <div class="card-flip-front">
        <CardPortrait :imageURL="frontURL"></CardPortrait>
      </div>
      <div class="card-flip-back">
        <CardPortrait :imageURL="backURL"></CardPortrait>
      </div>
    </div>
  </div>
</template>

<script>
import CardPortrait from '@/components/CardPortrait.vue';

export default {
  name: 'CardContainerFlip',
  components: { CardPortrait },
  props: {
    flipState: Boolean,
    frontURL: String,
    backURL: String,
  },
};
</script>

<style lang="scss" scoped>
.card-flip {
  background-color: transparent;
  width: 100%;
  height: 100%;
  max-width: 250px;
  margin: 0 auto;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.card-flip-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.card-flip-inner::before {
  content: '';
  display: block;
  width: 1px;
  height: 0;
  padding-bottom: calc(100% / (3 / 4));
}

.isFlipped .card-flip-inner {
  transform: rotateY(180deg);
}

.card-flip-front,
.card-flip-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-flip-back {
  transform: rotateY(180deg);
}
</style>
