<template>
  <div>
    <div class="share-bg" />
    <div class="share-card">
      <component
        :is="cardComponent"
        :orientation="getOrientation"
        :imageURL="imageURL"
        :flipState="cardFlipState"
        :backUrl="getBackUrl"
      />
      <div class="info">
        <span class="tag">@QIDDIYAGAMING</span>
        <div class="socials">
          <a class="twitter" href="https://x.com/QiddiyaGaming" target="_blank">
            <svg
              width="66"
              height="67"
              viewBox="0 0 66 67"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M31.307 37.5667L28.7626 33.9525L8.46554 5.06822H17.1973L33.5911 28.3723L36.1355 32.0153L57.4156 62.3164H48.6838L31.307 37.5956V37.5667ZM57.4156 0.789062L36.5692 24.9027L19.9152 0.789062H0.716797L25.9002 37.2197L0.716797 66.3642H6.4127L28.4157 40.9206L45.9949 66.3642H65.1933L39.0846 28.5747L63.1115 0.789062H57.4156Z" fill="white"/>
            </svg>
          </a>
          <a class="discord" href="https://discord.com/invite/qiddiyagaming" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="86"
              height="65"
              viewBox="0 0 86 65"
              fill="none">
              <path d="M72.5394 5.57279C66.9302 2.99951 61.0319 1.17798 54.9313 0.166016C54.1506 1.49602 53.2832 3.31756 52.676 4.76322C46.1995 3.80908 39.6362 3.80908 33.1596 4.76322C32.4657 3.2019 31.685 1.64059 30.8465 0.166016C24.7458 1.17798 18.8475 3.02842 13.2384 5.6017C2.13571 22.0822 -0.87127 38.158 0.632217 53.9735C7.13769 58.802 14.4527 62.474 22.2304 64.816C23.9941 62.474 25.5265 59.9586 26.8565 57.3564C24.3411 56.4311 21.8834 55.2746 19.5704 53.8868C20.1776 53.4242 20.7558 52.9616 21.3341 52.4989C35.01 58.9177 50.8545 58.9177 64.5304 52.4989C65.1087 52.9905 65.7159 53.4242 66.3231 53.8868C64.01 55.2457 61.5524 56.4311 59.0369 57.3564C60.3669 59.9586 61.9283 62.4451 63.663 64.816C71.4407 62.474 78.7557 58.802 85.2612 53.9735C87.0249 35.6136 82.2253 19.6824 72.5683 5.57279M28.7648 44.2298C24.5435 44.2298 21.1028 40.3843 21.1028 35.6715C21.1028 30.9586 24.4856 27.0842 28.7648 27.0842C33.0439 27.0842 36.4846 30.9297 36.4268 35.6425C36.369 40.3554 33.0439 44.2009 28.7648 44.2009M57.0997 44.2298C52.8784 44.2298 49.4377 40.3843 49.4377 35.6715C49.4377 30.9586 52.8206 27.0842 57.0997 27.0842C61.3789 27.0842 64.8196 30.9297 64.7617 35.6425C64.7039 40.3554 61.3789 44.2009 57.0997 44.2009" fill="white"/>
            </svg>
          </a>
          <a class="instagram" href="https://www.instagram.com/qiddiyagaming/" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="67"
              height="67"
              viewBox="0 0 67 67"
              fill="none">
              <path d="M51.3763 12.4121C49.2367 12.4121 47.502 14.118 47.502 16.2576C47.502 18.3971 49.2367 20.103 51.3763 20.103C53.5159 20.103 55.2507 18.3971 55.2507 16.2576C55.2507 14.118 53.5159 12.4121 51.3763 12.4121Z" fill="white"/>
              <path d="M34.2027 17.3281C25.2396 17.3281 17.9824 24.5564 17.9824 33.4617C17.9824 42.367 25.2685 49.5953 34.2027 49.5953C43.1369 49.5953 50.423 42.367 50.423 33.4617C50.423 24.5564 43.1369 17.3281 34.2027 17.3281ZM34.2027 43.8126C28.4779 43.8126 23.794 39.1865 23.794 33.4617C23.794 27.7369 28.449 23.1108 34.2027 23.1108C39.9564 23.1108 44.6115 27.7369 44.6115 33.4617C44.6115 39.1865 39.9564 43.8126 34.2027 43.8126Z" fill="white"/>
              <path d="M47.0678 66.2494H20.7567C9.85646 66.2494 0.951172 57.4309 0.951172 46.5595V20.393C0.951172 9.52166 9.82755 0.703125 20.7567 0.703125H47.0678C57.9681 0.703125 66.8733 9.55057 66.8733 20.393V46.5595C66.8733 57.4019 57.997 66.2494 47.0678 66.2494ZM20.7567 6.86164C13.2682 6.86164 7.16752 12.9334 7.16752 20.393V46.5595C7.16752 54.0191 13.2682 60.0909 20.7567 60.0909H47.0678C54.5563 60.0909 60.657 54.0191 60.657 46.5595V20.393C60.657 12.9334 54.5563 6.86164 47.0678 6.86164H20.7567Z" fill="white"/>
            </svg>
          </a>
        </div>
      </div>
      <div class="download-btn" @click="downloadBtnPress">
<svg width="287" height="88" viewBox="0 0 287 88" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="3.28906" y="3.36328" width="280" height="81" fill="#F2AF55"/>
<rect x="3.28906" y="3.36328" width="280" height="81" stroke="url(#paint0_linear_581_53)" stroke-width="6"/>
<path d="M76.5223 52.6033L79.1223 49.0033C80.9623 50.9633 83.8423 52.6833 87.5623 52.6833C91.4023 52.6833 92.9223 50.8033 92.9223 49.0433C92.9223 46.6433 90.0823 45.9233 86.8423 45.0833C82.4823 44.0033 77.3623 42.7233 77.3623 37.0833C77.3623 32.6833 81.2423 29.2833 87.0823 29.2833C91.2423 29.2833 94.6023 30.6033 97.0823 33.0033L94.4023 36.4833C92.3223 34.4033 89.4823 33.4433 86.6823 33.4433C83.9223 33.4433 82.1623 34.7633 82.1623 36.7633C82.1623 38.8433 84.8823 39.5233 88.0423 40.3233C92.4423 41.4433 97.6823 42.8033 97.6823 48.6033C97.6823 53.0433 94.5623 56.8433 87.3623 56.8433C82.4423 56.8433 78.8823 55.1233 76.5223 52.6033ZM127.232 56.3633H122.552V44.7233H108.672V56.3633H103.992V29.6833H108.672V40.6033H122.552V29.6833H127.232V56.3633ZM158.899 56.3633H153.779L151.819 51.2433H139.179L137.219 56.3633H132.099L142.579 29.6833H148.419L158.899 56.3633ZM150.499 47.1233L145.499 33.6433L140.499 47.1233H150.499ZM184.453 56.3633H179.053L173.133 46.4033H168.493V56.3633H163.813V29.6833H175.533C180.813 29.6833 184.253 33.1233 184.253 38.0433C184.253 42.8033 181.133 45.3633 177.933 45.8833L184.453 56.3633ZM179.453 38.0433C179.453 35.4833 177.533 33.8033 174.893 33.8033H168.493V42.2833H174.893C177.533 42.2833 179.453 40.6033 179.453 38.0433ZM209.21 56.3633H190.93V29.6833H209.21V33.8033H195.61V40.7233H208.93V44.8433H195.61V52.2433H209.21V56.3633Z" fill="#72130C"/>
<defs>
<linearGradient id="paint0_linear_581_53" x1="283.289" y1="43.8633" x2="3.28906" y2="43.8633" gradientUnits="userSpaceOnUse">
<stop stop-color="#956823"/>
<stop offset="0.1" stop-color="#FFE56E"/>
<stop offset="0.4999" stop-color="#F2AB3A"/>
<stop offset="0.5" stop-color="#F2AA39"/>
<stop offset="0.9" stop-color="#FFE56E"/>
<stop offset="1" stop-color="#956823"/>
</linearGradient>
</defs>
</svg>
      </div>
    </div>
  </div>
</template>

<script>
import CardBase from '@/components/CardBase.vue';
import CardFlip from '@/components/CardFlip.vue';
import CardFlip2 from '@/components/CardFlip2.vue';
import VueQRcode from 'vue-qrcode';

export default {
  name: 'ShareCard',
  components: {
    CardBase,
    CardFlip,
    CardFlip2,
    VueQRcode,
  },
  props: {
    imageURL: String,
    assetID: String,
    failedAsset: Boolean,
    metadata: Object,
  },
  data() {
    return {
      cardFlipState: false,
    };
  },
  computed: {
    getBackUrl() {
      if (this.metadata && this.metadata.backUrl) {
        return this.metadata.backUrl;
      }

      return 'none';
    },
    getOrientation() {
      if (this.metadata && this.metadata.orientation) {
        return this.metadata.orientation;
      }

      return 'portrait';
    },
    cardComponent() {
      if (this.metadata && this.metadata.componentType === 'cardFlip') {
        return 'CardFlip';
      }
      if (this.metadata && this.metadata.componentType === 'cardFlip2') {
        return 'CardFlip2';
      }
      return 'CardBase';
    },
  },
  methods: {
    changeFlipState() {
      this.cardFlipState = !this.cardFlipState;
    },
    async getFile() {
      let file;
      await fetch(this.imageURL)
        .then((response) => response.blob())
        .then((blob) => {
          file = new File([blob], 'qiddiyagamescon-profile.png', { type: 'image/png' });
        });
      return file;
    },
    twitterLink() {
      window.open('https://x.com/QiddiyaGaming', '_blank');
    },
    discordLink() {
      window.open('https://discord.com/invite/qiddiyagaming', '_blank');
    },
    instagramLink() {
      window.open('https://www.instagram.com/qiddiyagaming/', '_blank');
    },
    downloadWithLink() {
      const a = document.createElement('a');
      a.href = this.imageURL;
      a.download = 'qiddiyagamescon-profile.png';
      a.click();
    },
    async downloadBtnPress() {
      const isiOS =
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      if (isiOS) {
        // try navigator.share first
        try {
          await navigator.share({
            // eslint-disable-next-line no-restricted-globals
            url: location.href,
            files: [await this.getFile()],
          });
        } catch (err) {
          if (err.toString().includes('AbortError')) {
            // do nothing
          } else {
            // fallback to download image
            this.downloadWithLink();
          }
        }
      } else {
        // if useragent is not ios, download image
        this.downloadWithLink();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('/fonts/Proxima/stylesheet.css');

@media (max-width: 768px) {
  .share-card {
    width: 100%;
    min-height: calc(100svh - 45px - 28px);
    box-sizing: border-box;
    padding: 50px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .share-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #100D19;
  }

  .info {
    padding-top: 20px;
    display: flex;
    width: 204.579px;
    flex-direction: column;
    align-items: center;
    gap: 14.658px;
    display: flex;
    .tag {
      color: #FFF;
      text-align: center;
      font-family: "Proxima Nova";
      font-size: 24.973px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 2.199px;
    }
    .socials {
      display: flex;
      width: 147.151px;
      height: 34.662px;
      justify-content: center;
      align-items: center;
      gap: 29.077px;
      .twitter, .discord, .instagram {
        display: flex;
        svg {
          width: 35px;
          height: 35px;
          flex-shrink: 0;
        }
      }
    }
  }
  .download-btn {
    display: flex;
    svg {
      width: 140px;
    }
  }
}

@media (min-width: 768px) {
  .share-card {
    width: 25%;
    margin: 0 auto;
    max-height: 80%;
    box-sizing: border-box;
    padding: 50px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .share-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #100D19;
  }

  .info {
    padding-top: 20px;
    display: flex;
    width: 204.579px;
    flex-direction: column;
    align-items: center;
    gap: 14.658px;
    display: flex;
    .tag {
      color: #FFF;
      text-align: center;
      font-family: "Proxima Nova";
      font-size: 24.973px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 2.199px;
    }
    .socials {
      display: flex;
      width: 147.151px;
      height: 34.662px;
      justify-content: center;
      align-items: center;
      gap: 29.077px;
      .twitter, .discord, .instagram {
        display: flex;
        svg {
          width: 35px;
          height: 35px;
          flex-shrink: 0;
        }
      }
    }
  }
  .download-btn {
    display: flex;
    svg {
      width: 140px;
    }
  }
}

// ...

.top {
  margin-top: 47px;
  @include sm-mobile {
    margin-bottom: 30px;
  }
}

.middle {
  padding: 20px;
  display: grid;
  align-items: center;
  grid-template-columns: 1.5fr 2fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'flip .'
    'left right';

  @include sm-mobile {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      'flip'
      'left'
      'right';
    border: none;
  }

  .grid-left {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-right: 20px;
    grid-area: left;

    @include sm-mobile {
      padding-right: 0;
    }
  }
  .grid-right {
    grid-area: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;

    .title {
      font-size: 24px;
      text-align: center;

      &::before {
        content: 'Select';
        text-transform: uppercase;
        font-size: 2em;
        line-height: 1.2;
        font-weight: 900;
        white-space: pre-wrap;
        display: block;
      }
      &::after {
        content: 'one of the following';
        text-transform: uppercase;
        white-space: pre-wrap;
        display: block;
      }
      @include md-tablet {
        font-size: 30px;
        text-align: left;
      }
    }

    @include md-tablet {
      margin-left: 20px;
    }
  }
}

.card-item-failed {
  background-color: darkgray !important;
  width: 100%;
  color: black;
  //height: 100%;
  //max-width: 260px;

  item-image {
    width: 100%;
  }
  .item-image {
    background-color: white !important;
    background-position: center center !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
  }
  .item-image::before {
    content: '';
    display: block;
    width: 1px;
    height: 0;
    padding-bottom: calc(100% / (16 / 9));
  }

  .item-image::after {
    content: '';
    display: block;
  }

  .item-footer {
    width: 100%;
    padding: 0 0 0 13px;
    box-sizing: border-box;
    height: 60px;
    background-color: white;
    display: flex;
    justify-content: space-between;

    align-items: center;

    .item-description {
      font-size: 14px;
      font-family: 'brandon-grotesque';
      font-weight: 300;
    }

    .item-qr {
      width: 50px;
      height: 50px;

      & > * {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
