<template>
  <div>
    <div class="share-bg" />
    <div class="share-card">
      <!-- <div class="middle">
        <div class="grid-left"> -->
      <!-- <div class="card-item-failed" v-if="failedAsset">
        <div class="item-image">Coming Soon</div>
        <div class="item-footer" v-if="showFooter"></div>
      </div> -->
      <img src="/themes/mlbtheshow/top-banner.png" class="banner-top" />
      <component
        :is="cardComponent"
        :orientation="getOrientation"
        :imageURL="imageURL"
        :flipState="cardFlipState"
        :backUrl="getBackUrl"
      />
      <h1>Share Your Cover</h1>
      <button class="download-btn" @click="downloadBtnPress">
        <svg xmlns="http://www.w3.org/2000/svg" width="228" height="42" viewBox="0 0 228 42" fill="none">
          <path d="M0.828125 41.6066L21.7931 0.48407H227.173L206.208 41.6066H0.828125Z" fill="#D31245" />
        </svg>
        <span>Download</span>
      </button>
      <img src="/themes/mlbtheshow/bottom-banner.png" class="banner-bottom" />
      <!-- </div> -->
      <!-- <div class="grid-right">
          <div class="title"></div>
          <slot name="social"></slot>
        </div> -->
      <!-- </div>
      <div class="logo" v-if="$route.params.slug !== 'jordan24'" /> -->
    </div>
  </div>
</template>

<script>
import CardBase from '@/components/CardBase.vue';
import CardFlip from '@/components/CardFlip.vue';
import CardFlip2 from '@/components/CardFlip2.vue';
import VueQRcode from 'vue-qrcode';

export default {
  name: 'ShareCard',
  components: {
    CardBase,
    CardFlip,
    CardFlip2,
    VueQRcode,
  },
  props: {
    imageURL: String,
    assetID: String,
    failedAsset: Boolean,
    metadata: Object,
  },
  data() {
    return {
      cardFlipState: false,
    };
  },
  computed: {
    getBackUrl() {
      if (this.metadata && this.metadata.backUrl) {
        return this.metadata.backUrl;
      }

      return 'none';
    },
    getOrientation() {
      if (this.metadata && this.metadata.orientation) {
        return this.metadata.orientation;
      }

      return 'portrait';
    },
    cardComponent() {
      if (this.metadata && this.metadata.componentType === 'cardFlip') {
        return 'CardFlip';
      }
      if (this.metadata && this.metadata.componentType === 'cardFlip2') {
        return 'CardFlip2';
      }
      return 'CardBase';
    },
  },
  methods: {
    changeFlipState() {
      this.cardFlipState = !this.cardFlipState;
    },
    async getFile() {
      let file;
      await fetch(this.imageURL)
        .then((response) => response.blob())
        .then((blob) => {
          file = new File([blob], 'mlbtheshow24-cover.png', { type: 'image/png' });
        });
      return file;
    },
    async downloadBtnPress() {
      // try navigator.share first
      try {
        await navigator.share({
          title: 'MLB The Show 24',
          text: 'Check out my custom cover!',
          // eslint-disable-next-line no-restricted-globals
          url: location.href,
          files: [await this.getFile()],
        });
      } catch (err) {
        if (err.toString().includes('AbortError')) {
          // do nothing
        } else {
          // fallback to download image
          const a = document.createElement('a');
          a.href = this.imageURL;
          a.download = 'mlbtheshow24-cover.png';
          a.click();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'Chevy Sans Condensed';
  src: url('/fonts/ChevySansCondensed/ChevySans-CondensedBold.woff2') format('woff2'),
    url('/fonts/ChevySansCondensed/ChevySans-CondensedBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.share-card {
  width: 100%;
  max-width: 834px;
  // height: calc(100dvh - 28px - 12px);
  margin: 12px auto 28px auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0a203e;
}
.share-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0a203e;
  z-index: -1;
}
.banner-top {
  // position: absolute;
  // top: 0;
  // left: 50%;
  // transform: translateX(-50%);
  width: 100%;
  max-height: 30px;
  margin-bottom: 9vh;
  object-fit: contain;
}
.banner-bottom {
  // position: absolute;
  // bottom: 0;
  // left: 50%;
  // transform: translateX(-50%);
  width: 100%;
  max-height: 30px;
  margin-top: 8vh;
  margin-bottom: -30px;
  object-fit: contain;
}
::v-deep .grid-card img {
  margin: 0 auto;
  max-width: 300px;
}

h1 {
  width: 222px;
  color: #fff;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Chevy Sans Condensed';
  font-size: 52.151px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 95.876% */
  text-transform: uppercase;
  margin: 10vh auto 32px auto;
}

.download-btn {
  width: 226px;
  position: relative;
  display: block;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin: 0 auto;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: 'Chevy Sans Condensed';
    font-size: 22.5px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 133.333% */
  }
}

// ...

.top {
  margin-top: 47px;
  @include sm-mobile {
    margin-bottom: 30px;
  }
}

.middle {
  padding: 20px;
  display: grid;
  align-items: center;
  grid-template-columns: 1.5fr 2fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'flip .'
    'left right';

  @include sm-mobile {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      'flip'
      'left'
      'right';
    border: none;
  }

  .grid-left {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-right: 20px;
    grid-area: left;

    @include sm-mobile {
      padding-right: 0;
    }
  }
  .grid-right {
    grid-area: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;

    .title {
      font-size: 24px;
      text-align: center;

      &::before {
        content: 'Select';
        text-transform: uppercase;
        font-size: 2em;
        line-height: 1.2;
        font-weight: 900;
        white-space: pre-wrap;
        display: block;
      }
      &::after {
        content: 'one of the following';
        text-transform: uppercase;
        white-space: pre-wrap;
        display: block;
      }
      @include md-tablet {
        font-size: 30px;
        text-align: left;
      }
    }

    @include md-tablet {
      margin-left: 20px;
    }
  }
}

.card-item-failed {
  background-color: darkgray !important;
  width: 100%;
  color: black;
  //height: 100%;
  //max-width: 260px;

  item-image {
    width: 100%;
  }
  .item-image {
    background-color: white !important;
    background-position: center center !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
  }
  .item-image::before {
    content: '';
    display: block;
    width: 1px;
    height: 0;
    padding-bottom: calc(100% / (16 / 9));
  }

  .item-image::after {
    content: '';
    display: block;
  }

  .item-footer {
    width: 100%;
    padding: 0 0 0 13px;
    box-sizing: border-box;
    height: 60px;
    background-color: white;
    display: flex;
    justify-content: space-between;

    align-items: center;

    .item-description {
      font-size: 14px;
      font-family: 'brandon-grotesque';
      font-weight: 300;
    }

    .item-qr {
      width: 50px;
      height: 50px;

      & > * {
        width: 100%;
        height: auto;
      }
    }
  }
}

.qr-code {
  margin: 45px auto 0 0;
  width: 120px;
  height: 120px;
  & > * {
    width: 100%;
    height: auto;
  }
}
</style>
